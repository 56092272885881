


export function ImageLayoutPaymentProceed({image1, image2, image3} : {image1 : string, image2 : string, image3 : string}) {


    return (
        <div className="flex flex-col gap-6">

            <img alt="dongle-box" width={430} src={image1} />

            <div className=" items-center flex w-full justify-between">
                <div className="flex items-center justify-center col-span-1 h-[200px] w-[200px] border border-gray-600 rounded-md bg-Grey-background">
                    <img alt="dongle-dimensions" width={160} src={image2} />
                </div>
                <div className="flex items-center justify-center col-span-1 h-[200px] w-[200px] border border-gray-600 rounded-md bg-Grey-background">
                    <img alt="app-layout" width={190} src={image3} />
                </div>
            </div>
        </div>
    )

}