import { useTranslation } from "react-i18next";
import { Vehicle } from "../../../../../../../@types/vehicle";
import VehicleAnalysisPeriod from "../../../../../../../common/components/VehicleAnalysisPeriod";
import { useMutation } from "@apollo/client";
import { UPDATE_VEHICLE_MISC_INFO } from "../../../../../../../services/graphql/fleet/mutations";
import toast from "react-hot-toast";



export default function FleetVehicleAnalysisPeriod({ vehicle, refetchVehicle }: { vehicle?: Vehicle, refetchVehicle: () => void }) {

    const { t } = useTranslation('editVehicle');

    const analysisFrom = vehicle?.analysisPeriod?.from;
    const analysisTo = vehicle?.analysisPeriod?.to;

    const [updateAnalysisPeriod] = useMutation(UPDATE_VEHICLE_MISC_INFO, {
        onCompleted() {
            refetchVehicle();
            toast.success("Successfully updated vehicle's analysis period");
        },
        onError() {
            toast.error('Failed to update vehicle analysis period');
        }
    });


    return (
        <div className="h-auto px-8">
            <h1 className="text-xl mb-4">{t('vehicle-analysis-period')}</h1>
            <VehicleAnalysisPeriod
                vehicleId={vehicle?.id}
                analysisFrom={vehicle?.analysisPeriod?.from?.split("T")[0]}
                analysisTo={vehicle?.analysisPeriod?.to?.split("T")[0]}
                onChangeAnalysisFrom={async (val?: string | null) => {
                    updateAnalysisPeriod({
                        variables: {
                            id: vehicle?.id,
                            vehicle: {
                                analysisPeriod: {
                                    from: val,
                                    to: analysisTo,
                                },
                            }

                        },
                    });
                }}
                onChangeAnalysisTo={(val?: string | null) => {
                    updateAnalysisPeriod({
                        variables: {
                            id: vehicle?.id,
                            vehicle: {
                                analysisPeriod: {
                                    from: analysisFrom,
                                    to: val,
                                },
                            }

                        },
                    });
                }}
            />
        </div>
    )

}