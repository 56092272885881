import { useState } from 'react';
import { ReactComponent as PlusIcon } from '../../../../../../assets/plus-icon.svg';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { DriverTripUploadModal } from '../../OnboardingPage/utils/DriverTripUploadModal';

import toast from 'react-hot-toast';
import { UPDATE_VEHICLE_MISC_INFO } from '../../../../../../services/graphql/fleet/mutations';
import { Button } from '../../../../../../electrify_frontend_common/components/Buttonv2';
import { ButtonSize, ButtonVariant } from '../../../../../../electrify_frontend_common/components/Buttonv2/types';
import VehicleAnalysisPeriod from '../../../../../../common/components/VehicleAnalysisPeriod';
import { Vehicle } from '../../../../../../@types/vehicle';

export function TripData({
	vehicle,
	refetchVehicle,
}: {
	vehicle?: Vehicle;
	refetchVehicle: () => void;
}) {
	const { t } = useTranslation('driverSettings');

	const analysisFrom = vehicle?.analysisPeriod?.from;
	const analysisTo = vehicle?.analysisPeriod?.to;

	const [tripUploaderOpen, setTripUploaderOpen] = useState(false);

	const [updateAnalysisPeriod] = useMutation(UPDATE_VEHICLE_MISC_INFO, {
		onCompleted() {
			refetchVehicle();
			toast.success("Successfully updated vehicle's analysis period");
		},
		onError() {
			toast.error('Failed to update vehicle analysis period');
		}
	});

	return (
		<>
			{tripUploaderOpen ? (
				<DriverTripUploadModal
					isOpen={tripUploaderOpen}
					close={() => {
						refetchVehicle();
						setTripUploaderOpen(false);
					}}
				/>
			) : null}

			<div className="mt-10">
				<div className="flex justify-between">
					<h1 className="text-2xl">{t('trip-data.title')}</h1>
					<Button
						onClick={() => setTripUploaderOpen(true)}
						variant={ButtonVariant.TERTIARY}
						size={ButtonSize.MEDIUM}
					>
						<div className="flex items-center">
							<PlusIcon className="mr-2" />
							<span>{t('trip-data.button')}</span>
						</div>
					</Button>
				</div>
				<VehicleAnalysisPeriod
					vehicleId={vehicle?.id}
					analysisFrom={analysisFrom?.split('T')[0]}
					analysisTo={analysisTo?.split('T')[0]}
					onChangeAnalysisFrom={async (val?: string | null) => {
						updateAnalysisPeriod({
							variables: {
								id: vehicle?.id,
								vehicle: {
									analysisPeriod: {
										from: val,
										to: analysisTo,
									},
								}

							},
						});
					}}
					onChangeAnalysisTo={(val?: string | null) => {
						updateAnalysisPeriod({
							variables: {
								id: vehicle?.id,
								vehicle: {
									analysisPeriod: {
										from: analysisFrom,
										to: val,
									},
								}

							},
						});
					}}
				/>
			</div>
		</>
	);
}
