import { ReactElement } from "react";
import { CheckIcon } from "../../../../../../../../electrify_frontend_common/icons/CheckIcon";
import { useLayout } from "../../../../../../../../hooks/state/useLayout";
import { Logo } from "../../../../../../../../common/components/LayoutWrapper/Logo";
import { useTranslation } from "react-i18next";



export function IncludedInProductLabel({ label, fontSize, noBold = false }: { label: string, fontSize?: string | number, noBold?: boolean }) {


    return (
        <div className={`flex w-full ${noBold ? '' : 'font-bold'}  mb-1`}>
            <div className="w-[10%]">
                <CheckIcon className="mr-1" />
            </div>
            <div style={{
                fontSize: fontSize
            }} className={`w-[90%]`}>{label}</div>
        </div>
    )

}

export function ImageLayout({ imageMain, image1, image2 }: { imageMain: ReactElement, image1: ReactElement, image2: ReactElement }) {

    return (
        <div className="flex w-full mt-6">
            <div className="w-2/3">
                {imageMain}
                <div className="flex items-center">
                    <div className="flex items-center justify-center h-full w-1/2">
                        {image1}
                    </div>
                    <div className="w-1/2">
                        {image2}
                    </div>
                </div>
            </div>
        </div>
    )
}


export function ProductInformationLayout({
    price,
    title,
    currencySymbol,
    checkList,
    description,
    additionalInfo
}: {
    price: number,
    title: string,
    currencySymbol: string,
    checkList: ReactElement,
    description: string,
    additionalInfo?: ReactElement;
}) {

    const { t } = useTranslation("driverPaymentSystem");

    const { localeCode } = useLayout();

    return (
        <section>
            <Logo className="mb-4" />
            <div className="mb-3 font-[900] text-2xl text-Black-default">{title}</div>
            <section className="mb-3">
                <div className="flex justify-between items-center">
                    <div className="text-[2em] font-extrabold text-Black-default">
                        {price.toLocaleString(localeCode, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}{currencySymbol}{" *"}
                    </div>
                    {additionalInfo ? additionalInfo : null}
                </div>
                <div>* {t("includingVat")}</div>
            </section>
            <div className="font-semibold text-sm">{description}</div>
            <div className="my-3 mt-4">
                {checkList}
            </div>
        </section>
    )



}