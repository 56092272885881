
import { useTranslation } from "react-i18next";
import ModalWrapper from "../../../../../../../../../electrify_frontend_common/components/ModalWrapper";
import SimpleModalHeader from "../../../../../../../../../electrify_frontend_common/components/ModalWrapper/Headers";
import { ManualVehicleInput } from "../../../../../../../../../common/components/VehicleEditor/ManualVehicleInput";
import { UPDATE_VEHICLE_BRAND_AND_MODEL_INFO } from "../../../../../../../../../services/graphql/fleet/mutations";




export default function ManualModal({
  useManual,
  onClose,
  vehicleData,
}: {
  useManual: boolean;
  onClose: () => void;
  vehicleData: any;
}) {

  const { t } = useTranslation("editVehicle");

  return (
    <ModalWrapper
      isOpen={true}
      close={onClose}
      className="w-1/2 h-full">
      <>
        <SimpleModalHeader
          title={"Select vehicle manually"}
          onCancel={onClose}
          className="p-8"
        />

        <div className="max-h-full overflow-y-auto">
          <div className="p-8">
            <ManualVehicleInput
              vehicleData={vehicleData}
              onMutationSuccessful={onClose}
              mutation={UPDATE_VEHICLE_BRAND_AND_MODEL_INFO}
            />
          </div>
        </div>
      </>
    </ModalWrapper>
  );
}
