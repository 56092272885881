import { useEffect, useState } from "react";
import { Tariff } from "../../../../@types/paymentProcess";
import { useQuery } from "react-query";
import { checkDriverVehicleSubscription, getTariffsForDriverUser } from "../../../../services/rest/paymentProcess";
import { SubscriptionState } from "../../../../enums";
import { useUserData } from "../../../../hooks/state/useUserData";
import { useLayout } from "../../../../hooks/state/useLayout";



export function useProductInfo() {

    const { user } = useUserData();
    const { localeCode } = useLayout();
    const langCode = localeCode.split("-")[0];

    const [selectedTariff, setSelectedTariff] = useState<Tariff | undefined>(undefined);

    const { data: tariffs } = useQuery<Tariff[]>(['tariffs'], getTariffsForDriverUser);

    const { data: vehicleSubscription } = useQuery<{
        product: any,
        state: SubscriptionState,
        clientSecret: string | null
    }>(["userPaidVehicles"], checkDriverVehicleSubscription);



    useEffect(() => {
        const matchingTariff = tariffs?.filter((t: Tariff) => t.product.name === user?.accountInfo?.preSelectedProduct)[0];
        if (matchingTariff) {
            setSelectedTariff(matchingTariff);
        }
    }, [user, tariffs, vehicleSubscription])

    const tariffPrices = selectedTariff?.tariffPrices.sort((a, b) => b.price - a.price);
    const currencySymbol = selectedTariff?.tariffPrices[0]?.invoiceCurrency?.symbol
    const price = tariffPrices ? tariffPrices[0].price : 0;

    const productSwitch = () => {
        const websiteString = process.env.REACT_APP_ENV === 'production' ? `https://chargylize.com/${langCode}/drivers/electrify-driver#pricing` : `https://chargylize.webflow.io/${langCode}/drivers/electrify-driver#pricing`;
        window.open(websiteString, "_blank")

    }


    return {
        currencySymbol,
        price,
        productSwitch
    }


}