import { DocumentNode, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_BRANDS, GET_MATCHED_VEHICLES, GET_MODELS, GET_VARIANTS } from "../../../../services/graphql/fleet/queries";
import { useEffect, useState } from "react";
import { useForm } from "../../../../hooks/utils/useForm";
import { ModelInfo, Vehicle, VehicleInput } from "../../../../@types/vehicle";
import toast from "react-hot-toast";


export function useManualVehicleSelector({ vehicleData, onMutationSuccessful, mutation }: { vehicleData: Vehicle | undefined, onMutationSuccessful: () => void, mutation: DocumentNode }) {

    const manualInputForm = useForm<VehicleInput>();

    const { data: brandsResponse } = useQuery(GET_BRANDS);

    const [getModels, { data: modelsResponse, loading }] = useLazyQuery(
        GET_MODELS
    );

    const [getVariants, { data: variantsResponse }] = useLazyQuery(GET_VARIANTS);

    const [getMatchedVehicles, { data: matchedVehiclesResponse }] = useLazyQuery(GET_MATCHED_VEHICLES);

    console.log("vehicle data: ", vehicleData);
    const [initialModelId, setInitialModelId] = useState(vehicleData?.modelInfo?.modelId);


    useEffect(() => {
        if (!vehicleData?.modelInfo?.useVin) {
            manualInputForm.updateFormState({
                modelInfo: {
                    brand: vehicleData?.modelInfo?.brand,
                    model: vehicleData?.modelInfo?.model,
                    variant: vehicleData?.modelInfo?.variant,
                }
            });
        }
    }, [vehicleData]);

    const [updateVehicle, { loading: updateLoading }] = useMutation(
        mutation,
        {
            onCompleted: (data) => {
                toast.success('Vehicle information updated successfully');
                onMutationSuccessful();
            },
            onError: (e) => {
                toast.error(`Something went wrong while updating the vehicle's information`);
                console.log(e.graphQLErrors);
            },
        }
    );

    const saveManual = () => {
        const updatedInfo = {
            ...manualInputForm.formState, modelInfo: {
                ...manualInputForm?.formState?.modelInfo,
                useVin: false
            }
        };
        updateVehicle({
            variables: {
                id: vehicleData?.id,
                vehicle: updatedInfo,
            },
        });
    };

    const handleChange = (key: keyof ModelInfo, val: any) => {
        let modelInfo = { ...manualInputForm.formState.modelInfo };
        if (!modelInfo) modelInfo = {};
        modelInfo[key] = val;
        manualInputForm.updateFormState({
            ...manualInputForm.formState,
            modelInfo,
        });
    };

    const resetOnBrandChange = () => {
        manualInputForm.updateFormState({
            ...manualInputForm.formState,
            modelInfo: {
                brand: manualInputForm?.formState?.modelInfo?.brand,
                model: null,
                variant: null,
                modelId: null,
            },
        });
    };

    const resetOnModelChange = () => {
        manualInputForm.updateFormState({
            ...manualInputForm.formState,
            modelInfo: {
                brand: manualInputForm?.formState?.modelInfo?.brand,
                model: manualInputForm?.formState?.modelInfo?.model,
                variant: null,
                modelId: null,
            },
        });
    };

    const resetOnVariantChange = () => {
        manualInputForm.updateFormState({
            ...manualInputForm.formState,
            modelInfo: {
                brand: manualInputForm?.formState?.modelInfo?.brand,
                model: manualInputForm?.formState?.modelInfo?.model,
                variant: manualInputForm?.formState?.modelInfo?.variant,
                modelId: null,
            },
        });
    };

    // TODO reset forms when changing upstream variables ----> Simplify this

    useEffect(() => {
        if (manualInputForm.formState?.modelInfo?.brand) {
            resetOnBrandChange();
            getModels({ variables: { brand: manualInputForm.formState?.modelInfo?.brand } });
        }
    }, [manualInputForm.formState?.modelInfo?.brand]);

    useEffect(() => {
        if (manualInputForm.formState?.modelInfo?.model) {
            resetOnModelChange();
            getVariants({
                variables: {
                    brand: manualInputForm.formState?.modelInfo?.brand,
                    model: manualInputForm.formState?.modelInfo?.model,
                },
            });
        }
    }, [manualInputForm.formState?.modelInfo?.model]);

    useEffect(() => {
        if (manualInputForm.formState?.modelInfo?.variant) {
            resetOnVariantChange();
            getMatchedVehicles({
                variables: {
                    brand: manualInputForm.formState?.modelInfo?.brand,
                    model: manualInputForm.formState?.modelInfo?.model,
                    range: manualInputForm.formState?.modelInfo?.variant,
                },
            });
        }
    }, [manualInputForm.formState?.modelInfo?.variant]);


    return {
        manualInputForm,
        brandsResponse,
        modelsResponse,
        handleChange,
        variantsResponse,
        matchedVehiclesResponse,
        initialModelId,
        setInitialModelId,
        saveManual
    }

}