import dongleBoxImg from "../../../../assets/driver_payment/dongle_box.webp";
import dongleImg from "../../../../assets/driver_payment/dongle_large.png";
import dongleAppLayoutImg from "../../../../assets/driver_payment/app_layout.webp";

import { useTranslation } from "react-i18next";
import { IncludedInProductLabel, ProductInformationLayout } from "../../Electrify/pages/Payments/steps/Checkout/ProductInfo/components";
import { usePriceCalculator } from "../../Electrify/pages/Payments/steps/Checkout/ProductInfo/usePriceCalculator";
import { useLayout } from "../../../../hooks/state/useLayout";
import { useContext, useEffect, useState } from "react";
import { PaymentContext } from "../../../../contexts/PaymentContext";
import { DriverPaymentGatewayContext } from "../../contexts/DriverPaymentGatewayContext";
import { Tariff } from "../../../../@types/paymentProcess";
import { useMutation, useQuery } from "react-query";
import { checkDriverVehicleSubscription, confirmPaymentIntent, getTariffsForDriverUser } from "../../../../services/rest/paymentProcess";
import { SubscriptionState } from "../../../../enums";
import { useUserData } from "../../../../hooks/state/useUserData";
import { Button } from "../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonVariant } from "../../../../electrify_frontend_common/components/Buttonv2/types";
import { ImageLayoutPaymentProceed } from "./components";
import { useProductInfo } from "./useProductInfo";





export function DriverDonglePaymentProceed() {

    const { t } = useTranslation("driverPaymentSystem");

    const { openPaymentModal } = useContext(DriverPaymentGatewayContext);


    const { localeCode } = useLayout();

    const { price, currencySymbol, productSwitch } = useProductInfo();



    return (
        <div className="w-full h-full p-8 grid grid-cols-2 gap-16">
            <div className="flex w-full col-span-1">
                <ImageLayoutPaymentProceed
                    image1={dongleBoxImg}
                    image2={dongleImg}
                    image3={dongleAppLayoutImg}
                />
            </div>


            <div className="text-2xl col-span-1">
                <div className="text-[30px] font-[700]">{t("paymentProceedPages.dongleLeasingSelected.title")}</div>
                <div className="text-[20px] font-[400] mt-4">{t("paymentProceedPages.dongleLeasingSelected.descriptionMain")}</div>
                <section className="my-8">
                    <div className="flex items-center">
                        <div className="mr-10 text-[40px] font-[400] text-Black-default">
                            {price.toLocaleString(localeCode, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}{currencySymbol}{" *"}
                        </div>
                        <div className="flex items-center text-Grey-dark font-[400] text-sm">
                            <div>{t("products.driverDongle.inStock")}</div>
                            <div className="mx-1">|</div>
                            <div>{t("products.driverDongle.nowAvailable")}</div>
                        </div>
                    </div>
                    <div className="text-sm mt-2">* {t("includingVat")}</div>
                </section>
                <div className="font-[400] text-sm text-Grey-dark">{t("paymentProceedPages.dongleLeasingSelected.descriptionSecondary")}</div>
                <div className="my-3 mt-4">
                    <IncludedInProductLabel fontSize={14} label={t("products.driverDongle.checklist.point1")} />
                    <IncludedInProductLabel fontSize={14} label={t("products.driverDongle.checklist.point2")} />
                    <IncludedInProductLabel fontSize={14} label={t("products.driverDongle.checklist.point3", {
                        price: price?.toLocaleString(localeCode, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }),
                        currencySymbol
                    })} />
                </div>
                <div className="flex flex-col w-2/3 gap-4">
                    <Button
                        variant={ButtonVariant.PRIMARY}
                        onClick={() => {
                            openPaymentModal();
                        }}
                    >
                        {t("paymentProceedPages.dongleLeasingSelected.button1")}
                    </Button>
                    <Button
                        variant={ButtonVariant.SECONDRY}
                        onClick={productSwitch}
                    >
                        {t("paymentProceedPages.dongleLeasingSelected.button2")}
                    </Button>
                </div>
            </div>

        </div>
    )



}