import { useEffect, useState } from "react";

export type IuseForm<T> = { formState: Partial<T>, initializeForm: (obj: Partial<T>) => void, updateFormState: (obj: Partial<T>) => void, resetFormState: () => void, resetUnsavedChanges: () => void, unsavedChanges: boolean }

export function useForm<T>(): IuseForm<T> {

    const [formState, setFormState] = useState<Partial<T>>({} as T);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const initializeForm = (obj: Partial<T>) => {
        setFormState({ ...formState, ...obj });
    }

    const updateFormState = (obj: Partial<T>) => {
        setUnsavedChanges(true);
        setFormState({ ...formState, ...obj });
    }

    const resetFormState = () => {
        setUnsavedChanges(false);
        setFormState({} as T);
    }


    return {
        formState,
        initializeForm,
        updateFormState,
        resetFormState,
        resetUnsavedChanges: () => setUnsavedChanges(false),
        unsavedChanges
    };

}