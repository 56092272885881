import { Vehicle } from "../../../../../../../../@types/vehicle";
import { VinVehicleInput } from "../../../../../../../../common/components/VehicleEditor/VinVehicleInput";
import ModalWrapper from "../../../../../../../../electrify_frontend_common/components/ModalWrapper";
import SimpleModalHeader from "../../../../../../../../electrify_frontend_common/components/ModalWrapper/Headers";
import { UPDATE_VEHICLE_BRAND_AND_MODEL_INFO } from "../../../../../../../../services/graphql/fleet/mutations";


export default function VinModal({useVin, vehicleData, onClose }: { useVin: boolean, vehicleData: Vehicle, onClose: () => void }) {

  return (
    <ModalWrapper
      isOpen={useVin}
      close={onClose}
      className="w-6/12">
      <div className="bg-white w-full rounded pt-0 overflow-y-auto max-h-full flex flex-col space-y-9">
        <SimpleModalHeader title={"Enter Vin"} onCancel={onClose} className="p-8" />
        <div className="w-1/2 p-8 pt-4">
          <VinVehicleInput
            vehicleData={vehicleData}
            onSuccessUpdate={onClose}
            mutation={UPDATE_VEHICLE_BRAND_AND_MODEL_INFO}
          />
        </div>
      </div>
    </ModalWrapper>
  );
}
