/* React imports */
import { useEffect, useMemo, useState } from "react";

/* Third party imports */
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

/* Local imports */


import VehicleTariff from "./components/VehicleTariff";
import EditVehicleHeader from "./components/EditVehicleHeader";
import VehicleHomeLocations from "./components/VehicleHomeLocations";
import VehicleAnalysisPeriod from "./components/FleetVehicleAnalysisPeriod";
import VehicleAnualCosts from "./components/VehicleAnualCosts";
import VehicleInformationModal from "./components/VehicleInformationModal";
import { Vehicle, VehicleInput } from "../../../../../../@types/vehicle";
import { GET_VEHICLE_DATA } from "../../../../../../services/graphql/fleet/queries";
import { useForm } from "../../../../../../hooks/utils/useForm";
import ModalWrapper from "../../../../../../electrify_frontend_common/components/ModalWrapper";
import { UPDATE_VEHICLE_MISC_INFO } from "../../../../../../services/graphql/fleet/mutations";
import toast from "react-hot-toast";


export default function VehicleEditor({ vehicleId, onClose }: { vehicleId: string, onClose: () => void }) {

    const [updateVinError, setUpdateVinError] = useState(false);
    const form = useForm<VehicleInput>();



    const { data: vehicleData, refetch: refetchVehicleData } = useQuery<{ vehicle: Vehicle }>(GET_VEHICLE_DATA, {
        variables: { id: vehicleId },
        onCompleted: (data: { vehicle: Vehicle }) => {
            const vehicleData = data.vehicle;
            form.resetUnsavedChanges();
            form.initializeForm({
                modelInfo: {
                    modelId: vehicleData?.modelInfo?.modelId,
                    brand: vehicleData?.modelInfo?.brand,
                    model: vehicleData?.modelInfo?.model,
                    variant: vehicleData?.modelInfo?.variant,
                },
                name: vehicleData?.name,
                useVin: vehicleData?.useVin,
                vin: vehicleData?.vin,
                analysisPeriod: {
                    from: vehicleData?.analysisPeriod?.from?.split("T")[0],
                    to: vehicleData?.analysisPeriod?.to?.split("T")[0],
                },
                yearlyTax: vehicleData.yearlyTax,
                yearlyMaintenanceCost: vehicleData.yearlyMaintenanceCost,
                yearlyInsuranceCost: vehicleData.yearlyInsuranceCost,
                plannedReplacementDate: vehicleData.plannedReplacementDate?.split(
                    "T"
                )[0],
            });
        },
        onError: (error) => console.log("ERRRR>>>", error),
    });

    const [updateVehicleMiscInfo] = useMutation(
        UPDATE_VEHICLE_MISC_INFO,
        {
            onCompleted: (data) => {
                // onClose();
                toast.success('Vehicle information updated successfully');
            },
            onError: (e) => {
                if (e.message.includes("Vin")) {
                    setUpdateVinError(true);
                }
                toast.error(`Something went wrong while updating the vehicle's information`);

            },
        }
    );

    const processVehicleToUpdate = () => {
        const updatedInfo = { ...form.formState };
        return {
            variables: {
                id: vehicleId,
                vehicle: updatedInfo,
            },
        };
    };

    const processedVehicle = useMemo(() => {
        return vehicleData?.vehicle;
    }, [vehicleData]);

    useEffect(() => {
        if (updateVinError) {
            setUpdateVinError(false);
        }
    }, [form.formState]);

    return (
        <ModalWrapper
            isOpen={!!vehicleId}
            close={() => {
                if (form.unsavedChanges) {
                    updateVehicleMiscInfo(processVehicleToUpdate());
                    onClose();
                } else {
                    onClose();
                }
            }}
            className="w-1/2">

            <>
                <EditVehicleHeader
                    form={form}
                    vehicle={processedVehicle}
                    onClose={onClose}
                    updateVehicleMiscInfo={() => updateVehicleMiscInfo(processVehicleToUpdate())}
                />
                <div className="max-h-full overflow-y-auto">
                    <div className="py-8">
                        <VehicleTariff vehicleId={vehicleId} />

                        <div className="mt-8">
                            <VehicleInformationModal
                                vehicleData={processedVehicle}
                                refetchVehicleData={refetchVehicleData}
                            />
                        </div>

                        <div className="mt-8">
                            <VehicleHomeLocations vehicleId={vehicleId} />
                        </div>

                        <div className="mt-8">
                            <VehicleAnalysisPeriod vehicle={processedVehicle} refetchVehicle={refetchVehicleData} />
                        </div>

                        <div className="mt-8">
                            <VehicleAnualCosts form={form} updateVehicleMiscInfo={() => updateVehicleMiscInfo(processVehicleToUpdate())} />
                        </div>


                    </div>
                </div>
            </>
        </ModalWrapper>
    );
}
