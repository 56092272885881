import { useTranslation } from "react-i18next"
import { ReactComponent as CalendarIcon } from '../../../../../../../assets/calendar-icon.svg';
import { IuseForm } from "../../../../../../../hooks/utils/useForm";
import { VehicleInput } from "../../../../../../../@types/vehicle";
import { useLayout } from "../../../../../../../hooks/state/useLayout";
import Input from "../../../../../../../common/components/misc/Input";




export default function VehicleAnualCosts({ form, updateVehicleMiscInfo }: { form: IuseForm<VehicleInput>, updateVehicleMiscInfo: () => void }) {

    const { t } = useTranslation('editVehicle');

    const { currencySymbol } = useLayout();


    return (
        <div className="px-8">
            <h1 className="text-xl mb-4">{t('vehicle-annual-costs-and-replacement')}</h1>

            <div className="flex">
                <Input
                    className={"p-2"}
                    onChange={(e) => form.updateFormState({ 'yearlyMaintenanceCost': parseFloat(e.target?.value) })}
                    value={form.formState?.yearlyMaintenanceCost}
                    type="number"
                    label={t('vehicle-maintenance')}
                    unit={currencySymbol || undefined}
                    onBlur={updateVehicleMiscInfo}
                />
                <Input
                    className={"p-2"}
                    onChange={(e) => form.updateFormState({ 'yearlyTax': parseFloat(e.target?.value) })}
                    value={form.formState?.yearlyTax}
                    type="number"
                    label={t('vehicle-tax-per-year')}
                    unit={currencySymbol || undefined}
                    onBlur={updateVehicleMiscInfo}
                />
            </div>

            <div className="flex">
                <Input
                    className={"p-2"}
                    onChange={(e) => form.updateFormState({ 'yearlyInsuranceCost': parseFloat(e.target?.value) })}
                    value={form.formState?.yearlyInsuranceCost}
                    type="number"
                    label={t('vehicle-insurance')}
                    unit={currencySymbol || undefined}
                    onBlur={updateVehicleMiscInfo}
                />
                <Input
                    className={"p-2"}
                    onChange={(e) => form.updateFormState({ 'plannedReplacementDate': e.target?.value })}
                    value={form.formState?.plannedReplacementDate}
                    type="date"
                    label={t('vehicle-replacement-date')}
                    customIcon={<CalendarIcon />}
                    onBlur={updateVehicleMiscInfo}
                />
            </div>

        </div>
    )


}