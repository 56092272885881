import { useContext, useEffect, useMemo, useState } from "react";
import { useUserData } from "../../../../hooks/state/useUserData";
import { Button } from "../../../../electrify_frontend_common/components/Buttonv2";
import { DriverPaymentGatewayContext } from "../../contexts/DriverPaymentGatewayContext";
import { useLayout } from "../../../../hooks/state/useLayout";
import { useTranslation } from "react-i18next";
import { DriverDonglePaymentProceed } from "./DriverDonglePaymentProceed";
import { DriverDataUploadPaymentProceed } from "./DriverDataUploadPaymentProceed";
import { NoProductSelected } from "./NoProductSelected";


function SelectedProductElectrifyTeaser() {

    const { user } = useUserData();

    const preselectedProduct = user?.accountInfo?.preSelectedProduct;

    const { localeCode } = useLayout();

    const langCode = localeCode.split("-")[0];

    const { openPaymentModal, agreeWithPayment } = useContext(DriverPaymentGatewayContext);

    const pageBasedOnSelectedProduct = useMemo(() => {
        if (preselectedProduct === "driver_dongle") return <DriverDonglePaymentProceed />
        else return <DriverDataUploadPaymentProceed />
    }, [preselectedProduct])


    return (
        <div>
            {pageBasedOnSelectedProduct}
        </div>
    )

}



export function ElectrifyTeaser() {

    const { t, i18n } = useTranslation("electrifyTeaser");
    const { setHeader, localeCode } = useLayout();
    const langCode = localeCode.split("-")[0];
    const language = i18n.language;

    const { user } = useUserData();
    const preselectedProduct = user?.accountInfo?.preSelectedProduct;


    useEffect(() => {
        setHeader({
            defaultHeaderTitle: `${t("title")}`,
            defaultHeaderDescription: `${t("description")}`,
        });
    }, [language]);


    return (
        <div className="flex flex-col flex-1 h-full w-full items-center justify-center">
            {preselectedProduct ?
                <SelectedProductElectrifyTeaser /> :
                <NoProductSelected />}
        </div>
    )


}