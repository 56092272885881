import appLayoutFront from "../../../../assets/driver_payment/app_layout_front.png";

import { useTranslation } from "react-i18next";
import { IncludedInProductLabel } from "../../Electrify/pages/Payments/steps/Checkout/ProductInfo/components";
import { Button } from "../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../electrify_frontend_common/components/Buttonv2/types";
import { useProductInfo } from "./useProductInfo";



export function NoProductSelected() {

    const { t } = useTranslation("driverPaymentSystem");

    const { productSwitch } = useProductInfo();


    return (
        <div className="w-full h-full items-center justify-center grid grid-cols-2 gap-8 px-16 bg-[#F8F3F6] my-10">

            <div className="text-2xl col-span-1">
                <div className="text-[30px] font-[700]">{t("paymentProceedPages.noProductSelected.title")}</div>
                <div className="text-[18px] font-[400] mt-4">{t("paymentProceedPages.noProductSelected.descriptionMain")}</div>


                <div className="my-3 mt-4">
                    <IncludedInProductLabel noBold fontSize={18} label={t("paymentProceedPages.noProductSelected.point1")} />
                    <IncludedInProductLabel noBold fontSize={18} label={t("paymentProceedPages.noProductSelected.point2")} />
                    <IncludedInProductLabel noBold fontSize={18} label={t("paymentProceedPages.noProductSelected.point2")} />
                </div>
                <div className="text-[18px] font-[400] my-4">{t("paymentProceedPages.noProductSelected.descriptionSecondary")}</div>
                <div className="flex flex-col w-2/3 gap-4 ">

                    <Button
                        variant={ButtonVariant.PRIMARY}
                        size={ButtonSize.MEDIUM}
                        onClick={productSwitch}
                    >
                        {t("paymentProceedPages.noProductSelected.buttonText")}
                    </Button>
                </div>
            </div>

            <div className="flex w-full col-span-1">
                <img src={appLayoutFront} />
            </div>

        </div>
    )



}